const APPLICATION_PATH = "/";
const VOUCHER_TYPE = {
    Liquidate: -2,// Thanh lý
    StockOut: -1,//Xuất kho vật tư
    StockIn: 1,//Nhập kho vật tư
    Allocation: 2,//Cấp phát
    Transfer: 3,//Điều chuyển
    ReceivingAsset: 4,//Tiếp nhận tài sản
    TransferToAnotherUnit: 5//Điều chuyển đơn vị khác
};

const RESPONSE_CODE = {
    success: 200
}

const expireCertificate= {
    expired: -1,
    expireUnder12M: 0,
    expireOver12M: 1
}

const typeCV = {
    BAHRI: 1,
    DIMSCO: 2,
    DY: 3,
    EVERGREEN: 4,
    JW: 5,
    SEOYANG: 6,
    SINOSIN: 7,
    SYNERGY: 8,
    EAST_STAR: 9
}

const nameCV = {
    BAHRI: "BAHRI",
    DIMSCO: "DIMSCO",
    DY: "DY",
    EVERGREEN: "EVERGREEN",
    JW: "JW",
    SEOYANG: "SEOYANG",
    SINOSIN: "SINOSIN",
    SYNERGY: "SYNERGY",
    EAST_STAR: "EAST_STAR"
}
const styleTable = {
    header: {
        backgroundColor: "#8298AB",
        color: "#fff",
        height: "50px",
    },
    columnFirst: {
        borderRight: "1px solid #DBDBDB",
        borderLeft: "1px solid #DBDBDB",
        paddingLeft: 8,
        paddingRight: 8,
        paddingTop: 4,
        paddingBottom: 4
    }
}

const actionMethod = {
    view: 2,
    delete: 1,
    edit: 0,
    print: 3,
    viewCV: 4,
}

const relative = {
    wife: 1,
    husband: 2,
    children: 3,
    father: 4,
    grandParents: 5,
    other: 6,
    mother:7
}

const typeDocument = {
    competency: '034'
}

const gender = {
    male: 1,
    female: 2
}

const listRoles = {
    admin: 1,
    user: 2,
    superAdmin: 3
}

const titleName = {
    mr: "MR",
    mrs: "MRS",
    miss: "MISS"
}

const keyConst = {
    enter: "Enter"
}
//const APPLICATION_PATH="/asset_develop/";//Đặt homepage tại package.json giống như tại đây nếu deploy develop
module.exports = Object.freeze({
    //ROOT_PATH : "/egret/",
    ROOT_PATH: APPLICATION_PATH,
    ACTIVE_LAYOUT: "layout2",//layout1 = vertical, layout2=horizontal
    //     API_ENPOINT: "http://localhost:8992/ess",    //local
    //     // API_ENPOINT: "http://globits.net:8992/",
    //     // API_ENPOINT: "http://localhost:8081/core",    //local
    //     API_ENPOINT: "http://globits.net:8062/tb",
    // API_ENPOINT:  "https://367a-222-252-21-66.ngrok-free.app/ess",
    API_ENPOINT: "https://admin.eastar-shipping.vn/ess", //local

    // API_ENPOINT: "http://localhost:8992/ess",    //local
    // API_ENPOINT: "http://essapi.oceantech.vn/ess",
    IMAGE_URL: "D:/image/",
    //API_ENPOINT: "http://globits.net:8081/core",
    LOGIN_PAGE: APPLICATION_PATH + "session/signin",//Nếu là Spring
    HOME_PAGE: APPLICATION_PATH + "sailor_manager/sailor",//Nếu là Spring
    //HOME_PAGE:APPLICATION_PATH+"dashboard/learning-management"//Nếu là Keycloak
    //HOME_PAGE:APPLICATION_PATH+"landing3",//Link trang landing khi bắt đầu
    VOUCHER_TYPE: VOUCHER_TYPE,
    RESPONSE_CODE: RESPONSE_CODE,
    expireCertificate: expireCertificate,
    typeCV: typeCV,
    styleTable: styleTable,
    actionMethod: actionMethod,
    MATERIAL_DEPARTMENT_CODE: "VPB4",
    relative: relative,
    keyConst: keyConst,
    typeDocument: typeDocument,
    gender: gender,
    listRoles: listRoles,
    nameCV: nameCV,
    titleName: titleName
});